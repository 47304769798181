<template>
  <v-app id="inspire">
    <v-main class="grey lighten-4">
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="6" md="3">
            <v-img :src="FRONT_ASSETS + 'frontend/img/pinnacle_big.png'" contain max-height="200"></v-img>
          </v-col>
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-2" :loading="loading">
              <v-toolbar color="primary" dark flat dense>
                <v-toolbar-title>Login</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="Email"
                    name="login"
                    prepend-icon="mdi-account"
                    type="email"
                    v-model="email"
                    :error-messages="form_error.email"
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    v-model="password"
                    :error-messages="form_error.password"
                    @keyup.enter.native="login()"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <!-- <v-btn text color="primary" to="/forgot-password">Forgot Password</v-btn> -->
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  v-on:click="login()"
                  :loading="loading"
                  :disabled="loading"
                >Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Alert />
  </v-app>
</template>

<script>
import Alert from "@/components/Alert.vue";
import { mapMutations } from 'vuex';

export default {
  name: "Login",
  data() {
    return {
      form_error: {},
      email: null,
      password: null,
      loading: false,
      data: {},
    };
  },
  components: {
    Alert
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    loginVendor(vendorDetails) {
      this.data = vendorDetails;

      localStorage.setItem(
        "vendor_data",
        JSON.stringify(vendorDetails)
      );

      this.$axios.defaults.headers.common = {
        Authorization: "Bearer " + vendorDetails.token,
        Accept: "application/json",
      };

      this.$router.push("/booth");
    },
    login() {
      let _self = this;
      this.loading = "secondary";
      this.$axios.post(`login`, {
        email: this.email,
        password: this.password,
      }).then((response) => {
        _self.form_error = {};
        let response_data = response.data;
        if (response_data.status) {
          this.loginVendor(response_data.user_data)
        } else {
          let errors = response_data.error;
          for (let key in errors) {
            console.log(errors[key]);
            _self.form_error[errors[key].field] = errors[key].message;
          }
        }
        _self.loading = false;
      }).catch((e) => {
        console.log(e);
        _self.loading = false;
      });
    },
    loginAsVendor(code) {
      if (!code) {
        console.log("CODE NOT FOUND");
        return;
      }

      this.loading = true;

      this.$axios
        .post("login_using_code", {
          code: code,
        })
        .then((res) => {
          const resData = res.data;

          if (resData.status) {
            this.loginVendor(resData.user_data)
          } else {
            let errorMsg = "Something went wrong";

            if (resData.error && Array.isArray(resData.error)) {
              errorMsg = resData.error[0].message;
            }

            this.setAlert({
              show: true,
              variant: "danger",
              message: errorMsg,
              dismissCountDown: 4000,
            });
          }
        })
        .catch(() => {
          let errorMsg = "Something went wrong";
          
          this.setAlert({
            show: true,
            variant: "danger",
            message: errorMsg,
            dismissCountDown: 4000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    if (this.$route.query.code) {
      this.loginAsVendor(this.$route.query.code);
    }
  }
};
</script>