<template>
  <span>
    <v-speed-dial bottom fixed class="mb-3 bg-white mr-4" right>
      <template v-slot:activator>
        <v-row dark>
          <v-btn color="light-blue darken-4" class="white--text" @click="showBoothPreview" to="/booth/preview" tile>
            <v-icon left>
              mdi-eye
            </v-icon>
            Click here for preview
          </v-btn>
        </v-row>
      </template>
    </v-speed-dial>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1920 1440"
      style="enable-background: new 0 0 1920 1440"
      xml:space="preserve"
    >
      <g id="Color_Selections_1_">
        <g id="White_xA0_Image_1_">
          <image
            style="overflow: visible"
            width="1920"
            height="1440"
            id="White_xA0_Image"
            :xlink:href="vendor_details.booth_image"
          ></image>
        </g>
      </g>
      <g id="Social_Media_Links_1_">
        <a href="javascript:;" @click="openLink('/profile')">
        <g :id="'Rectangle_9_'+items.social_meida_id" v-for="(items,index) in social_links" :key="items.social_meida_id">
          <g>
            <rect
              :x="(index == 0) ? xb : xb + (index * 45)"
              y="1090"
              class="st0"
              width="30"
              height="30"
            />
          </g>
        </g>
        <g :id="'Layer_10_xA0_Image_1_'+items.social_meida_id" v-for="(items,index) in social_links" :key="'sub_' + items.social_meida_id">
            <image
              style="overflow: visible"
              width="30"
              height="30"
              :id="`Layer_10_xA0_${items.social_meida_id}_Image`"
              :x="(index == 0) ? xb : xb + (index * 45)" y="1090"
              :xlink:href="`${asset}${items.name}_icon.png`"
              class="cursor_edit"
            ></image>
        </g>
        </a>
      </g>
      <g id="Rectangle_8_1_" class="st1">
        <g id="Rectangle_8">
          <g>
            <rect x="355" y="1012" class="st0_transparent" width="245" height="80" ref="contact_image" />
          </g>
        </g>
      </g>
      <g id="Rectangle_6_1_" class="st1" >
        <g id="Rectangle_6">
          <g>
            <rect x="1080" y="978" class="st0_transparent" width="460" height="146" />
          </g>
        </g>
      </g>
      <g id="Layer_8_xA0_Image_1_" @click="addObjectView(vendor_objects.D6)">
        <image
          style="overflow: visible"
          width="460"
          height="146"
          id="Layer_8_xA0_Image"
          :xlink:href="vendor_objects.D6.upload_url"
          transform="matrix(1 0 0 1 1080 978)"
          class="cursor_edit"
        ></image>
      </g>
      <g id="Layer_8_xA0_Image_1__delete" v-if="!isDefaultContent(vendor_objects.D6.upload_url)">
        <a href="javascript:;" @click="deleteObject(vendor_objects.D6)">
          <image
            style="overflow: visible"
            width="30"
            height="30"
            id="Layer_8_xA0_Image__delete"
            :xlink:href="asset + 'delete_icon.png'"
            transform="matrix(1 0 0 1 1505 980)"
            class="delete_content"
          ></image>
        </a>
      </g>
      <g id="Rectangle_7_1_" class="st1">
        <g id="Rectangle_7">
          <g>
            <rect x="1612" y="833" class="st0_transparent" width="200" height="370" />
          </g>
        </g>
      </g>
      <g id="Layer_7_xA0_Image_1_" @click="addObjectView(vendor_objects.D5)">
        <a
          href="javascript:;"
        >
        <image
          style="overflow: visible"
          width="200"
          height="370"
          id="Layer_7_xA0_Image"
          :xlink:href="vendor_objects.D5.upload_url"
          transform="matrix(1 0 0 1 1612 833)"
          class="cursor_edit"
        ></image>
        </a>
      </g>
      <g id="Layer_7_xA0_Image_1__delete" v-if="!isDefaultContent(vendor_objects.D5.upload_url)">
        <a href="javascript:;" @click="deleteObject(vendor_objects.D5)">
          <image
            style="overflow: visible"
            width="30"
            height="30"
            id="Layer_7_xA0_Image__delete"
            :xlink:href="asset + 'delete_icon.png'"
            transform="matrix(1 0 0 1 1780 835)"
            class="delete_content"
          ></image>
        </a>
      </g>
      <g id="Rectangle_5_1_" class="st1">
        <g id="Rectangle_5">
          <g>
            <rect x="970" y="688" class="st0_transparent" width="426" height="240" />
          </g>
        </g>
      </g>
      <g id="Layer_6_xA0_Image_1_" @click="addObjectView(vendor_objects.D1)">
        <a href="javascript:;">
          <image
            style="overflow: visible"
            width="426"
            height="240"
            id="Layer_6_xA0_Image"
            :xlink:href="vendor_objects.D1.link"
            transform="matrix(1 0 0 1 970 688)"
            class="cursor_edit"
          ></image>
        </a>
      </g>
      <g id="Layer_6_xA0_Image_1__delete" v-if="!isDefaultContent(vendor_objects.D1.upload_url)">
        <a href="javascript:;" @click="deleteObject(vendor_objects.D1)">
          <image
            style="overflow: visible"
            width="30"
            height="30"
            id="Layer_6_xA0_Image__delete"
            :xlink:href="asset + 'delete_icon.png'"
            transform="matrix(1 0 0 1 1360 690)"
            class="delete_content"
          ></image>
        </a>
      </g>
      <g id="Rectangle_4_1_" class="st1">
        <g id="Rectangle_4">
          <g>
            <rect x="770" y="688" class="st0_transparent" width="180" height="180" />
          </g>
        </g>
      </g>
      <g id="Layer_5_xA0_Image_1_" @click="addObjectView(vendor_objects.D3)">
        <a href="javascript:;">
          <image
            style="overflow: visible"
            width="180"
            height="180"
            id="Layer_5_xA0_Image"
            :xlink:href="vendor_objects.D3.upload_url"
            transform="matrix(1 0 0 1 770 688)"
            class="cursor_edit"
          ></image>
        </a>
      </g>
      <g id="Layer_5_xA0_Image_1__delete" v-if="!isDefaultContent(vendor_objects.D3.upload_url)">
        <a href="javascript:;" @click="deleteObject(vendor_objects.D3)">
          <image
            style="overflow: visible"
            width="30"
            height="30"
            id="Layer_5_xA0_Image__delete"
            :xlink:href="asset + 'delete_icon.png'"
            transform="matrix(1 0 0 1 920 688)"
            class="delete_content"
          ></image>
        </a>
      </g>
      <g id="Company_Bio_1_">
        <g id="Rectangle_3" ref="desc_image">
          <g>
            <rect x="550" y="688" class="st0" width="200" height="240" />
          </g>
        </g>
        <g id="Layer_4_xA0_Image_1_" @click="openLink('/profile')">
          <title>Click on description to update</title>
          <span></span>
        </g>
      </g>
      <g id="Rectangle_2_1_" class="st1">
        <g id="Rectangle_2">
          <g>
            <rect x="660" y="596" class="st0" width="240" height="48" />
          </g>
        </g>
      </g>
      <g id="Layer_3_xA0_Image_1_">
        <a href="javascript:;" @click="openLink('/resource/list')">
          <image
            style="overflow: visible"
            width="240"
            height="48"
            id="Layer_3_xA0_Image"
            :xlink:href="asset+'res_button.gif'"
            transform="matrix(1 0 0 1 660 596)"
          ></image>
        </a>
      </g>
      <g id="Rectangle_1_1_" class="st1">
        <g id="Rectangle_1">
          <g>
            <rect x="468" y="393" class="st0_transparent" width="432" height="186" />
          </g>
        </g>
      </g>
      <g id="Layer_2_xA0_Image_1_" @click="addObjectView(vendor_objects.D2)">
        <a href="javascript:;">
          <image
            style="overflow: visible"
            width="432"
            height="186"
            id="Layer_2_xA0_Image"
            :xlink:href="vendor_objects.D2.upload_url"
            transform="matrix(1 0 0 1 468 393)"
            class="cursor_edit"
          ></image>
        </a>
      </g>
      <g id="Layer_2_xA0_Image_1__delete" v-if="!isDefaultContent(vendor_objects.D2.upload_url)">
        <a href="javascript:;" @click="deleteObject(vendor_objects.D2)">
          <image
            style="overflow: visible"
            width="30"
            height="30"
            id="Layer_2_xA0_Image__delete"
            :xlink:href="asset + 'delete_icon.png'"
            transform="matrix(1 0 0 1 868 400)"
            class="delete_content"
          ></image>
        </a>
      </g>
    </svg>
     <span
      ref="custom_html"
      style="color: white; font-size: 13px;"
      class="pl-1 cursor_edit"
      v-if="vendor_details.comapny_desc"
      @click="openLink('/profile')"
      >
      <span class="ellipsis white_nested" ref="custom_sub_html" v-html="vendor_details.comapny_desc" @click="openLink('/profile')"></span>
    </span>
    <span v-else ref="custom_html" style="color: white; font-size: 13px;" class="pl-1 cursor_edit" @click="openLink('/profile')">
      Click on box to edit description
    </span>
     <!---COntact Details -->
      <span
      ref="custom_contact_html"
      style="color: white; font-size: 0.9vw;white-space: break-spaces;line-height: 1vw;"
      class="pl-1 cursor_edit ellipsis"
      v-if="vendor_details.object_data.D4"
      v-html="vendor_objects.D4.contact_details"
      @click="addObjectView(vendor_objects.D4)"
      >
    </span>
    <span v-else ref="custom_contact_html" style="color: white; font-size: 13px;" class="pl-1 cursor_edit" @click="addObjectView(vendor_objects.D4)">
      Click on box to edit contact details
    </span>
    <!---COntact Details -->
    <v-dialog v-model="show_preview" max-width="50%">
      <v-card :height="($vuetify.breakpoint.height-250) +'px'">
          <!-- <video :src="preview_data" width="100%" controls></video> -->
        <iframe frameborder="0" :src="preview_data" width="100%" height="100%"></iframe>
        <v-btn style="position:absolute;top:10px;right:10px" color="red" dark fab small @click="show_preview = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
    <AddObject v-bind:position="selected_position" />
    <v-dialog v-model="show_desc_modal" max-width="50%">
      <v-card>
        <v-card-title class="pa-3 pb-1">Company Description</v-card-title>
        <v-card-text class="pa-3 pb-0 rounded-lg"  v-html="vendor_details.comapny_desc" style="white-space: pre-wrap">
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="show_desc_modal = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="delete_modal" width="300">
      <v-card :loading="loading">
        <v-card-title class="pa-3 pb-1 text-center">Sure want to delete?</v-card-title>
        <v-card-actions class="pt-5">
          <v-btn color="success" @click="delete_modal = false"
            >Close</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="error" @click="deleteBoothActually"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="went_wrong_modal" width="400">
      <v-card>
        <v-card-title class="pa-3 pb-1 text-center">Something went wrong please try again!</v-card-title>
        <v-card-actions class="pt-5">
          <v-btn color="success" @click="went_wrong_modal = false"
            >Close</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import AddObject from "@/components/booth/AddObject";
import { mapState, mapMutations,mapActions } from "vuex";
export default {
  name: "MainImage",
  props: {
    drawer: Boolean,
  },
  components: {
    AddObject,
  },
  data() {
    return {
      is_preview: false,
      selected_booth_color: "grey",
      loading: false,
      preview_data: null,
      show_preview: false,
      show_desc_modal: false,
      selected_position: null,
      respresentative: [],
      desc_y: 0,
      xb:358,
      last_xb:358,
      booth_colors: [
        { name: "Red", color: "red" },
        { name: "Red-Orange", color: "red_orange" },
        { name: "Orange", color: "orange" },
        { name: "Yellow", color: "yellow" },
        { name: "Yellow-Orange", color: "yellow_orange" },
        { name: "Yellow-Green", color: "yellow_green" },
        { name: "Green", color: "green" },
        { name: "Blue-Green", color: "blue_green" },
        { name: "Blue", color: "blue" },
        { name: "Blue-Violet", color: "blue_violet" },
        { name: "Violet", color: "violet" },
        { name: "Red-Violet", color: "red_violet" },
      ],
      booth_image: null,
      desc_size : 180,
      delete_modal: false,
      went_wrong_modal: false,
      delete_object: ''
    };
  },
  watch: {
    "vendor_details.booth_color": function (value) {
      if (value) {
        this.selected_booth_color = this.vendor_details.booth_color;
      }
    },
    show_preview: function (value) {
      if (!value) {
        this.preview_data = null;
      }
    },
  },
  computed: {
    ...mapState("utils", ["maxWidth","isBoothPreview"]),
    ...mapState("vendor_booth", ["vendor_details", "vendor_objects"]),
    asset() {
      return process.env.VUE_APP_ASSETS;
    },
    intro_video_thumb() {
      return process.env.VUE_APP_ASSETS + "intro_video_thumb.png";
    },
    social_links(){
      return this.vendor_details.social_links
    }
  },
  methods: {
    ...mapMutations("utils", ["setUtilData"]),
    ...mapMutations("add_object", ["setObjectData"]),
    ...mapActions("vendor_booth", ["getBoothsDetails"]),
    addObjectView(object) {
      let _self = this;
      _self.setObjectData({
        key: "selected_object",
        data: object,
      });
      _self.setObjectData({
        key: "show_add_object_modal",
        data: true,
      });
      _self.loading = false;
    },
    openLink(route) {
      this.$router.push(route);
    },
    getContactDetails(details) {
      let contact_arr = details.split("\n");
      let x = 10;
      let y = 18;
      let svgTextHtml = ``;
      for (let i = 0; i <= contact_arr.length - 1; i++) {
        svgTextHtml += `<tspan x="${x}" y="${y}">${contact_arr[i]}</tspan>`;
        y = y + 18;
      }
      return svgTextHtml;
    },
    getDescription(description) {
      let svgTextLength = Math.round(description.length / 20);
      let svgTextHtml = ``;
      let x = 5;
      let y = 20;
      let diff = 24;
      let startFrom = 0;
      let endFrom = diff;
      for (let i = 1; i <= svgTextLength; i++) {
        if (i < 11) {
          var res = description.substring(startFrom, endFrom);
          svgTextHtml += `<tspan x="${x}" y="${y}">${res}</tspan>`;
          y = y + 20;
          startFrom = startFrom + diff;
          endFrom = endFrom + diff;
        }
      }
      this.desc_y = y;
      return svgTextHtml;
    },
    isShowReadMore(description) {
      return Math.round(description.length / 20) > 12;
    },
    openBoothDetails(vendor_id) {
      this.$router.push("/vendor/" + vendor_id);
    },
    updateBoothImage() {
      let _self = this;
      this.$axios
        .post(`update_booth_color`, { booth_color: _self.selected_booth_color })
        .then((response) => {
          if (response.data.status) {
            _self.getBoothsDetails(true);
          }
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    getCoords(elem) {
      // crossbrowser version
      var box = elem.getBoundingClientRect();
      var body = document.getElementsByTagName("svg")[0];
      var docEl = body;

      var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      var scrollLeft =
        window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

      var clientTop = docEl.clientTop || body.clientTop || 0;
      var clientLeft = docEl.clientLeft || body.clientLeft || 0;

      var top = box.top + scrollTop - clientTop;
      var left = box.left + scrollLeft - clientLeft;
      var width = box.width;
      var height = box.height;

      return {
        top: Math.round(top),
        left: Math.round(left),
        width: Math.round(width),
        height: Math.round(height),
      };
    },
    setWidthOfCustomHtml() {
      let desc_image = this.$refs["desc_image"];
      let custom_html = this.$refs["custom_html"];
      let custom_sub_html = this.$refs["custom_sub_html"];
      let bounds = this.getCoords(desc_image);
      custom_html.style.position = "absolute";
      custom_html.style.left = bounds.left + "px";
      custom_html.style.top = bounds.top - 45 + "px";
      custom_html.style.width = bounds.width + "px";
      custom_html.style.height = bounds.height + "px";
      if(custom_sub_html){
        custom_sub_html.style.height = bounds.height-20 + "px";
      }
      this.desc_size = bounds.height;
    },
    setWidthOfCustomContact() {
      let desc_image = this.$refs["contact_image"];
      let custom_html = this.$refs["custom_contact_html"];
      let bounds = this.getCoords(desc_image);
      custom_html.style.position = "absolute";
      custom_html.style.left = bounds.left + "px";
      custom_html.style.top = bounds.top - 50 + "px";
      custom_html.style.width = bounds.width + "px";
      custom_html.style.height = bounds.height + "px";
      this.desc_size = bounds.height;
    },
    showBoothPreview(){
      this.setUtilData({
        key : 'isBoothPreview',
        data : true
      })
    },
    isDefaultContent(path){
      return path.indexOf('default') > 0
    },
    deleteObject(object){
      this.delete_modal = true
      this.delete_object = object
    },
    deleteBoothActually(){
      this.loading = true
      this.$axios.post('/object/delete',this.delete_object)
      .then((res) => {
        const data = res.data
        if(data.status){
          this.getBoothsDetails(true);
        } else {
          this.went_wrong_modal = true
        }
      }).catch(() => {
        this.went_wrong_modal = true
      }).then(() => {
        this.loading = false
        this.delete_object = ''
        this.delete_modal = false
      })
    }
  },
  mounted() {
    this.getBoothsDetails(true).then(() => {
      this.setWidthOfCustomHtml();
      this.setWidthOfCustomContact()
    });
    window.onresize = () => {
      this.setWidthOfCustomHtml();
      this.setWidthOfCustomContact()
    };
    this.selected_booth_color = this.vendor_details.booth_color;
  }
};
</script>
<style type="text/css">
.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #00000069 !important;
}
.st0_transparent {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #0db04b00;
}
.st0_contact {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #727272;
}
.st1 {
  enable-background: new;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
span.ellipsis > p {
  margin-bottom: 0px;
  color: white;
}
span.ellipsis > p > * {
  color: white !important;
}
</style>